import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'

import 'normalize.css'
import '../../assets/fonts/styles.css'

import { Header } from './header'
import { Footer } from './footer'
import { FooterSmall } from './footer'
import { config } from '../../config'
import backgroundImg from '../../assets/background.jpg'

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html, body {
    overflow-x: hidden;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Morandi-Regular', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: ${config.colors.base};
    background: url(${backgroundImg}) repeat;

    @media (min-width: ${config.breakpoints.tablet}) {
      font-size: 20px;
    }
  }

  body.noscroll {
    height: 100vh;
    overflow-y: hidden;
  }

  a {
    color: inherit;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: ${config.colors.primary};
    }
  }

  h1,
  h2,
  h3 {
    line-height: 1.35;
    font-weight: normal;
    font-family: 'Morandi-Bold', sans-serif;
  }

  strong {
    font-family: 'Morandi-Bold', sans-serif;
    font-weight: normal;
  }

  p {
    margin-top: 0;
    margin-bottom: 1.5rem;

    @media (min-width: ${config.breakpoints.tablet}) {
      margin-bottom: 1.825rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Page = styled.div``

const Main = styled.main`
  margin-bottom: 118px;

  @media (min-width: ${config.breakpoints.tablet}) {
    margin-bottom: 160px;
  }
`

export function Layout({ children, smallFooter }) {
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <link
          rel="preload"
          href="//hello.myfonts.net/count/3b31b6"
          as="style"
          onload="this.onload=null;this.rel='stylesheet'"
        />
      </Helmet>
      <Page>
        <Header />
        <Main>{children}</Main>
        {smallFooter ? <FooterSmall /> : <Footer />}
      </Page>
    </>
  )
}
