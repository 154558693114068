import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'
import scrollbarWidth from 'scrollbar-width'

import { Container } from '../../container'
import backgroundImg from '../../../assets/background.jpg'
import { config } from '../../../config'

const Icon = styled.span`
  display: block;
  height: 2px;
  width: 100%;
  background-color: ${config.colors.base};
  position: absolute;
  top: 50%;
  top: calc(50% - 1px);
  opacity: 1;
  transition: opacity 0.15s ease, background-color 0.25s ease;
`

const Button = styled.button`
  display: block;
  background: none;
  margin: 0;
  padding: 0;
  position: relative;
  border: 0;
  cursor: pointer;
  outline: none; /* TODO */
  z-index: 999;
  width: 46px;
  height: 34px;

  @media (min-width: ${config.breakpoints.tablet}) {
    width: 66px;
    height: 50px;
    position: fixed;
    top: 154px;
    right: 50px;
  }

  @media (min-width: 1722px) {
    right: auto;
    left: calc(((100% / 2) + 1642px / 2) - 66px);
  }

  &::before,
  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    background-color: ${config.colors.base};
    position: absolute;
    left: 0;
    transition: transform 0.25s ease, background-color 0.25s ease;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  ${(props) =>
    props.isOpen &&
    css`
      &::before {
        transform: translateY(15px) rotate(37deg);

        @media (min-width: ${config.breakpoints.tablet}) {
          transform: translateY(23px) rotate(37deg);
        }
      }

      &::after {
        transform: translateY(-16px) rotate(-37deg);

        @media (min-width: ${config.breakpoints.tablet}) {
          transform: translateY(-24px) rotate(143deg);
        }
      }

      > span {
        opacity: 0;
      }
    `}

  &:hover {
    &::before,
    &::after {
      background-color: ${config.colors.primary};
    }

    ${Icon} {
      background-color: ${config.colors.primary};
    }
  }
`

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  color: #2c5664;
  background: url(${backgroundImg}) repeat;
  display: flex;
  justify-content: center;
  padding-top: 70px;

  @media (min-width: ${config.breakpoints.tablet}) {
    padding-top: 0;
    align-items: center;
  }

  &::after {
    content: '';
    display: block;
    background: rgba(146, 193, 153, 0.4);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  padding-left: 0;

  @media (min-width: ${config.breakpoints.tablet}) {
    max-width: 66.666666%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8px;
  }
`

const Nav = styled.nav`
  margin-bottom: 60px;

  @media (min-width: ${config.breakpoints.tablet}) {
    margin-bottom: 120px;
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li``

const Meta = styled.div`
  > ul {
    margin: -4px -16px;
    padding: 0;
    list-style: none;
    display: flex;
  }

  > ul > li {
  }

  > ul > li > a {
    display: block;
    text-decoration: none;
    padding: 4px 16px;
    font-size: 14px;

    &.is-active {
      font-family: 'Morandi-Bold', sans-serif;
    }
  }
`

const Link = styled(GatsbyLink)`
  font-size: 33px;
  font-family: 'Morandi-Bold', sans-serif;
  text-decoration: none;
  display: block;
  padding-top: 6px;
  padding-bottom: 6px;

  &.is-active {
    color: ${config.colors.primary};
  }

  @media (min-width: ${config.breakpoints.tablet}) {
    font-size: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export function Menu() {
  const [open, setOpen] = useState(false)

  function handleToggleOpen() {
    setOpen(!open)
  }

  useEffect(() => {
    const width = scrollbarWidth()

    if (open) {
      document.body.style.paddingRight = `${width}px`
      document.body.classList.add('noscroll')
    } else {
      document.body.style.paddingRight = 0
      document.body.classList.remove('noscroll')
    }
  }, [open])

  return (
    <>
      <Button onClick={handleToggleOpen} isOpen={open} aria-label="Menü öffnen">
        <Icon />
      </Button>

      <AnimatePresence>
        {open && (
          <Overlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0 }}
          >
            <Container>
              <Content>
                <Nav>
                  <List>
                    <Item>
                      <Link to="/kriterien" activeClassName="is-active">
                        Kriterien
                      </Link>
                    </Item>
                    <Item>
                      <Link
                        to="/news"
                        partiallyActive={true}
                        activeClassName="is-active"
                      >
                        News
                      </Link>
                    </Item>
                    <Item>
                      <Link to="/aktionen" activeClassName="is-active">
                        Aktionen
                      </Link>
                    </Item>
                    <Item>
                      <Link to="/akteure" activeClassName="is-active">
                        Akteure
                      </Link>
                    </Item>
                    <Item>
                      <Link to="/kontakt" activeClassName="is-active">
                        Kontakt
                      </Link>
                    </Item>
                  </List>
                </Nav>
                <Meta>
                  <ul>
                    <li>
                      <GatsbyLink to="/impressum" activeClassName="is-active">
                        Impressum
                      </GatsbyLink>
                    </li>
                    <li>
                      <GatsbyLink to="/datenschutz" activeClassName="is-active">
                        Datenschutz
                      </GatsbyLink>
                    </li>
                  </ul>
                </Meta>
              </Content>
            </Container>
          </Overlay>
        )}
      </AnimatePresence>
    </>
  )
}
