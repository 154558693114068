import React from 'react'
import styled, { css } from 'styled-components'

import { config, breakpoint } from '../../../config'

const Button = styled.button`
  display: none;

  @media ${breakpoint.desktop} {
    display: block;
    width: 66px;
    height: auto;
    position: fixed;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    bottom: 76px;
    right: 40px;
    cursor: pointer;
    transition: transform 0.25s ease;
    transform: scaleY(-1);

    ${(props) =>
      props.isVisible &&
      css`
        transform: scaleY(1);
      `}
  }

  @media (min-width: 1722px) {
    right: auto;
    left: calc(((100% / 2) + 1642px / 2) - 66px);
  }

  &:hover svg path {
    fill: ${config.colors.primary};
  }
`

const Icon = styled.svg`
  display: block;
  width: 100%;
  height: auto;

  path {
    fill: ${config.colors.base};
    transition: fill 0.25s ease;
  }
`

export function Top({ onClick, isVisible }) {
  return (
    <Button onClick={onClick} aria-label="nach oben" isVisible={isVisible}>
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.186 34.042">
        <g
          id="Gruppe_138"
          data-name="Gruppe 138"
          transform="rotate(-90 -314.3755 1384.9115)"
        >
          <path
            id="Pfad_2"
            data-name="Pfad 2"
            fill="currentColor"
            d="M0 0h46.093v2.049H0z"
            transform="rotate(135 176.5834078 1087.655058)"
          />
          <path
            id="Rechteck_4"
            data-name="Rechteck 4"
            fill="currentColor"
            transform="rotate(-135 893.52798282 645.24862551)"
            d="M0 0h46.093v2.049H0z"
          />
        </g>
      </Icon>
    </Button>
  )
}
