export const config = {
  colors: {
    base: '#2C5664',
    primary: '#92C199',
  },
  breakpoints: {
    tablet: '860px',
    desktop: '1280px',
    screen: '1680px',
  },
}

export const breakpointSize = {
  tablet: '860px',
  desktop: '1280px',
  screen: '1680px',
}

export const breakpoint = {
  tablet: `(min-width: ${breakpointSize.tablet})`,
  desktop: `(min-width: ${breakpointSize.desktop})`,
  screen: `(min-width: ${breakpointSize.screen})`,
}

export const promoFilterItems = [
  {
    key: 'current',
    label: 'Aktuell',
  },
  {
    key: 'archive',
    label: 'Archiv',
  },
]

export const actorFilterItems = [
  { key: 'Essen und Trinken', label: 'Essen und Trinken' },
  { key: 'Einkaufen', label: 'Einkaufen' },
  { key: 'Büro und Verwaltung', label: 'Büro und Verwaltung' },
  { key: null, label: 'Alle ansehen' },
]
