import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled, { css } from 'styled-components'

import arrowRightImg from '../assets/arrow-right.svg'
import arrowRightPrimaryImg from '../assets/arrow-right-lightgreen.svg'
import { config } from '../config'

const sharedArrowWrapperStyles = css`
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;

  ${(props) =>
    props.primary &&
    css`
      color: ${config.colors.primary};

      &:hover {
        color: ${config.colors.base};
      }
    `}/* &:hover img {
    transform: translateX(10px);
  } */
`

const Internal = styled(GatsbyLink)`
  text-decoration: none;

  ${(props) =>
    props.arrow &&
    css`
      ${sharedArrowWrapperStyles}
    `}
`

const External = styled.a`
  ${(props) =>
    props.arrow &&
    css`
      ${sharedArrowWrapperStyles}
    `}
`

const ArrowImg = styled.img`
  display: block;
  width: 32px;
  height: auto;
  transition: transform 0.2s ease;
  margin-right: 12px;
  position: relative;
  top: -1px;

  @media (min-width: ${config.breakpoints.tablet}) {
    width: 40px;
    margin-right: 17px;
  }

  ${(props) =>
    props.arrowLast &&
    css`
      order: 2;
      margin-left: 12px;

      @media (min-width: ${config.breakpoints.tablet}) {
        margin-left: 17px;
      }
    `}
`

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export function Link({
  children,
  to,
  activeClassName,
  partiallyActive,
  arrow,
  primary = false,
  arrowLast,
  ...other
}) {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <Internal
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        arrow={arrow ? 1 : 0}
        primary={primary ? 1 : 0}
        {...other}
      >
        {arrow && (
          <ArrowImg
            src={primary ? arrowRightPrimaryImg : arrowRightImg}
            alt=""
            arrowLast={arrowLast}
          />
        )}
        <span>{children}</span>
      </Internal>
    )
  }
  return (
    <External href={to} arrow={arrow} primary={primary} {...other}>
      {arrow && (
        <ArrowImg src={primary ? arrowRightPrimaryImg : arrowRightImg} alt="" />
      )}
      <span>{children}</span>
    </External>
  )
}
