import React from 'react'
import styled, { css } from 'styled-components'

import { config } from '../config'

const Heading = styled.h1`
  font-size: 33px;
  color: #2c5664;
  margin: 0;
  padding: 0;

  @media (min-width: ${config.breakpoints.tablet}) {
    font-size: 42px;
  }

  ${(props) =>
    props.size === 2 &&
    css`
      font-size: 25px;

      @media (min-width: ${config.breakpoints.tablet}) {
        font-size: 32px;
      }
    `}

  /* Margin: Size 1 */
  ${(props) =>
    props.margin &&
    props.size === 1 &&
    css`
      margin-bottom: 24px;

      @media (min-width: ${config.breakpoints.tablet}) {
        margin-bottom: 68px;
      }
    `}

  /* Margin: Size 2 */
  ${(props) =>
    props.margin &&
    props.size === 2 &&
    css`
      margin-bottom: 16px;

      @media (min-width: ${config.breakpoints.tablet}) {
        margin-bottom: 28px;
      }
    `}

      /* Margin small: Size 2 */
  ${(props) =>
    props.margin === 'small' &&
    props.size === 2 &&
    css`
      margin-bottom: 10px;

      @media (min-width: ${config.breakpoints.tablet}) {
        margin-bottom: 20px;
      }
    `}
`

export function Title({
  children,
  size = 1,
  heading = 'h1',
  margin = false,
  ...props
}) {
  return (
    <Heading as={heading} size={size} margin={margin} {...props}>
      {children}
    </Heading>
  )
}
