import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { Menu } from './menu'
import { Container } from '../../container'
import logoImg from './assets/lokal-und-fair-logo.svg'
import { config } from '../../../config'

const Wrapper = styled.header`
  margin-top: 20px;
  margin-bottom: 28px;
  /* border: 1px solid red; */

  @media (min-width: ${config.breakpoints.tablet}) {
    margin-top: 64px;
    margin-bottom: 60px;
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.img`
  display: block;
  height: auto;
  width: 146px;
  position: relative;
  left: -8px;

  @media (min-width: ${config.breakpoints.tablet}) {
    width: 240px;
  }

  /** IE 11 hack */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 100%;
  }
`

export function Header() {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <Link to="/">
            <Logo src={logoImg} alt="Lokal + Fair Logo" />
          </Link>
          <Menu />
        </Inner>
      </Container>
    </Wrapper>
  )
}
