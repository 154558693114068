import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'

import { config, breakpoint } from '../../../config'
import { Container } from '../../container'
import { Link } from '../../link'
import { flexStyles, flexItemStyles, flexOffset, flexWidth } from '../../flex'
import { Top } from './top'

import lokalFairImg from './assets/lokal-und-fair.svg'
import fairTradeTownImg from './assets/FTT_LOGO_Vaduz.png'
import vaduzImg from './assets/vaduz.svg'

const Wrapper = styled.footer`
  ${flexStyles}
  font-size: 14px;
  line-height: 1.8;
  margin-bottom: 40px;
  position: relative;
  padding-top: 32px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    height: 1px;
    background: #2c5664;

    @media ${breakpoint.tablet} {
      left: 25px;
      right: 25px;
    }
  }

  @media ${breakpoint.tablet} {
    font-size: 17px;
    margin-bottom: 70px;
    padding-top: 116px;
  }
`

/** First column with logos and info text. */
const Main = styled.div`
  ${flexItemStyles}
  ${flexWidth(12)}
  display: flex;
  flex-direction: column;
  margin-bottom: 44px;

  @media ${breakpoint.tablet} {
    ${flexWidth(6)}
    margin-bottom: 0;
  }

  @media ${breakpoint.desktop} {
    ${flexWidth(4)}
    margin-bottom: 0;
  }
`

/** Second column with contact infos. */
const Contact = styled.div`
  ${flexItemStyles}

  display: flex;
  align-items: flex-end;

  .nowrap {
    white-space: nowrap;
  }

  @media ${breakpoint.tablet} {
    ${flexWidth(6)}
  }

  @media ${breakpoint.desktop} {
    ${flexWidth(2)}
  }
`

const ContactHeading = styled.h2`
  display: none;

  @media ${breakpoint.tablet} {
    display: block;
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: normal;
    font-family: 'Morandi-Regular', sans-serif;
    padding-bottom: 16px;
    border-bottom: 1px solid ${config.colors.base};
    margin-bottom: 16px;
    width: 125%;
  }
`

/** Third column with navigation. */
const Meta = styled.div`
  ${flexItemStyles}
  ${flexOffset(1)}
  ${flexWidth(2)}
  display: none;


  @media ${breakpoint.desktop} {
    display: flex;
    flex-direction: column;
  }
`

const Logos = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
`

const Logo = styled.li`
  max-width: 100%;
  flex: none;
  margin-bottom: 30px;
  margin-right: 26px;

  &:first-of-type {
    margin-left: -5px;
    margin-right: 20px;
    position: relative;
    top: 3px;

    @media ${breakpoint.tablet} {
      margin-right: 40px;
      top: 6px;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  /** IE 11 hack */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: none;
    width: 100px;
  }
`

const LogoImg = styled.img`
  display: block;

  @media (max-width: 860px) {
    height: 68px;

    ${(props) =>
      props.first &&
      css`
        height: 74px;
      `}

    ${(props) =>
      props.last &&
      css`
        height: 10px;
      `}
  }

  /** IE 11 hack */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 100%;
  }
`

const Legal = styled.ul`
  font-size: 14px;
  columns: 2;
  column-gap: 50px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: auto;

  a.is-active {
    font-family: 'Morandi-Bold', sans-serif;
  }

  @media ${breakpoint.tablet} {
    margin-bottom: 2px;
  }
`

const Info = styled.div`
  max-width: 380px;
  margin-top: auto;
`

const Nav = styled.ul`
  columns: 2;
  column-gap: 50px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: -6px;

  a.is-active {
    font-family: 'Morandi-Bold', sans-serif;
  }
`

export function Footer() {
  const [isVisible, setIsVisible] = useState(false)

  function handleTop() {
    if (isVisible) {
      window.scrollTo(0, 0)
    } else {
      const newPosition = window.scrollY + window.innerHeight - 200
      window.scrollTo(0, newPosition)
    }
  }

  function handleVisibility(visible) {
    setIsVisible(visible)
  }

  return (
    <>
      <VisibilitySensor onChange={handleVisibility}>
        <Container>
          <Wrapper>
            <Main>
              <Logos>
                <Logo>
                  <a href="/">
                    <LogoImg
                      src={lokalFairImg}
                      alt="lokal + fair"
                      height="98"
                      first
                    />
                  </a>
                </Logo>
                <Logo>
                  <a
                    href="http://www.fairtradetown.ch/de"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LogoImg
                      src={fairTradeTownImg}
                      alt="Fair Trade Town"
                      height="92"
                    />
                  </a>
                </Logo>
                <Logo>
                  <a
                    href="https://www.vaduz.li/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LogoImg
                      src={vaduzImg}
                      alt="Gemeinde Vaduz"
                      height="14"
                      last
                    />
                  </a>
                </Logo>
              </Logos>
              <Info>
                Lokal+Fair ist eine Initiative der Gemeinde Vaduz, der ersten
                Fair Trade Town in Liechtenstein.
              </Info>
            </Main>

            <Contact>
              <div>
                <ContactHeading>Kontakt</ContactHeading>
                <strong>Gemeinde Vaduz</strong>
                <br />
                Lokal+Fair
                <br />
                Rathaus
                <br />
                9490 Vaduz
                <br />
                <span className="nowrap">
                  Telefon <a href="tel:+4232377878">+423 237 78 78</a>
                </span>
                <br />
                <a href="mailto:lokalundfair@vaduz.li">lokalundfair@vaduz.li</a>
              </div>
            </Contact>

            <Meta>
              <Nav>
                <li>
                  <Link
                    to="/"
                    partiallyActive={false}
                    activeClassName="is-active"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/kriterien" activeClassName="is-active">
                    Kriterien
                  </Link>
                </li>
                <li>
                  <Link to="/news" activeClassName="is-active">
                    News
                  </Link>
                </li>
                <li>
                  <Link to="/aktionen" activeClassName="is-active">
                    Aktionen
                  </Link>
                </li>
                <li>
                  <Link to="/akteure" activeClassName="is-active">
                    Akteure
                  </Link>
                </li>
                <li>
                  <Link to="/kontakt" activeClassName="is-active">
                    Kontakt
                  </Link>
                </li>
              </Nav>
              <Legal>
                <li>
                  <Link to="/impressum" activeClassName="is-active">
                    Impressum
                  </Link>
                </li>
                <li>
                  <Link to="/datenschutz" activeClassName="is-active">
                    Datenschutz
                  </Link>
                </li>
              </Legal>
            </Meta>

            <Top onClick={handleTop} isVisible={isVisible} />
          </Wrapper>
        </Container>
      </VisibilitySensor>
    </>
  )
}
