import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { breakpoint } from '../../../config'

import { Container } from '../../container'
import { flexStyles, flexItemStyles, flexWidth, flexOffset } from '../../flex'

import lokalFairImg from './assets/lokal-und-fair.svg'
import fairTradeTownImg from './assets/FTT_LOGO_Vaduz.png'
import vaduzImg from './assets/vaduz.svg'

const Wrapper = styled.footer`
  font-size: 14px;
  line-height: 1.8;

  @media ${breakpoint.tablet} {
    font-size: 17px;
  }
`

const Main = styled.div`
  ${flexStyles}
  align-items: flex-end;
`

const Logos = styled.ul`
  ${flexItemStyles}
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  list-style: none;

  @media ${breakpoint.desktop} {
    ${flexOffset(2)}
    ${flexWidth(4)}
  }
`

const Info = styled.div`
  ${flexItemStyles}
  margin-bottom: 20px;

  @media ${breakpoint.desktop} {
    margin-bottom: 10px; /* bring text to the same baseline */
    ${flexWidth(3.2)}
  }
`

const Logo = styled.li`
  max-width: 100%;
  flex: none;
  margin-bottom: 20px;
  margin-right: 26px;

  &:first-of-type {
    margin-left: -5px;
    margin-right: 20px;
    margin-bottom: 15px;
    position: relative;
    top: 3px;

    @media ${breakpoint.tablet} {
      margin-right: 40px;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  /** IE 11 hack */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: none;
    width: 100px;
  }
`

const LogoImg = styled.img`
  display: block;

  @media (max-width: 860px) {
    height: 68px;

    ${(props) =>
      props.first &&
      css`
        height: 74px;
      `}

    ${(props) =>
      props.last &&
      css`
        height: 10px;
      `}
  }

  /** IE 11 hack */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 100%;
  }
`

export function FooterSmall() {
  return (
    <Container>
      <Wrapper>
        <Main>
          <Logos>
            <Logo>
              <Link to="/">
                <LogoImg
                  src={lokalFairImg}
                  alt="lokal + fair"
                  height="98"
                  first
                />
              </Link>
            </Logo>
            <Logo>
              <a
                href="http://www.fairtradetown.ch/de"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogoImg
                  src={fairTradeTownImg}
                  alt="Fair Trade Town"
                  height="92"
                />
              </a>
            </Logo>
            <Logo>
              <a
                href="https://www.vaduz.li/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogoImg src={vaduzImg} alt="Gemeinde Vaduz" height="14" last />
              </a>
            </Logo>
          </Logos>
          <Info>
            Lokal+Fair ist eine Initiative der Gemeinde Vaduz, der ersten Fair
            Trade Town in Liechtenstein.
          </Info>
        </Main>
      </Wrapper>
    </Container>
  )
}
