import React from 'react'
import styled, { css } from 'styled-components'

import { breakpoint } from '../config'

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 1742px;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  @media ${breakpoint.tablet} {
    padding-left: 50px;
    padding-right: 50px;
  }

  ${(props) =>
    props.width === 'full' &&
    css`
      width: 100%;
    `}
`

export function Container({ children, ...props }) {
  return <Wrapper {...props}>{children}</Wrapper>
}
