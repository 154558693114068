import React from 'react'
import styled, { css } from 'styled-components'

import { breakpoint } from '../config'

export function flexWidth(columns) {
  return css`
    flex: 0 0 ${(100 / 12) * columns}%;
    max-width: ${(100 / 12) * columns}%;
  `
}

export function flexOffset(columns) {
  return css`
    margin-left: ${(100 / 12) * columns}%;
  `
}

export function flex(width, offset) {
  return css`
    ${flexWidth(width)}
    ${flexOffset(offset)}
  `
}

export const flexStyles = css`
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;

  @media ${breakpoint.tablet} {
    margin-left: -25px;
    margin-right: -25px;
  }
`

export const flexItemStyles = css`
  padding-left: 10px;
  padding-right: 10px;
  flex: auto;

  @media ${breakpoint.tablet} {
    padding-left: 25px;
    padding-right: 25px;
  }
`

const Wrapper = styled.div`
  ${flexStyles}
`

const Child = styled.div`
  ${flexItemStyles}
  max-width: 100%;

  /** TODO: Abstract into function.  */

  /** Width */
  ${(props) =>
    props.width &&
    Number.isInteger(props.width) &&
    css`
      flex: 0 0 ${(100 / 12) * props.width}%;
      max-width: ${(100 / 12) * props.width}%;
    `}

  ${(props) =>
    props.width &&
    props.width.tablet &&
    css`
      @media ${breakpoint.tablet} {
        ${flexWidth(props.width.tablet)}
      }
    `}

  ${(props) =>
    props.width &&
    props.width.desktop &&
    css`
      @media ${breakpoint.desktop} {
        ${flexWidth(props.width.desktop)}
      }
    `}

  ${(props) =>
    props.width &&
    props.width.screen &&
    css`
      @media ${breakpoint.screen} {
        ${flexWidth(props.width.screen)}
      }
    `}

  /** Offset */
  ${(props) =>
    props.offset &&
    Number.isInteger(props.offset) &&
    css`
      ${flexOffset(props.offset)}
    `}

  ${(props) =>
    props.offset &&
    props.offset.tablet &&
    css`
      @media ${breakpoint.tablet} {
        ${flexOffset(props.offset.tablet)}
      }
    `}

  ${(props) =>
    props.offset &&
    props.offset.desktop &&
    css`
      @media ${breakpoint.desktop} {
        ${flexOffset(props.offset.desktop)}
      }
    `}

  ${(props) =>
    props.offset &&
    props.offset.screen &&
    css`
      @media ${breakpoint.screen} {
        ${flexOffset(props.offset.screen)}
      }
    `}
`

export function Flex({ children, align }) {
  return <Wrapper align={align}>{children}</Wrapper>
}

Flex.Item = function Item({ children, offset = 0, width = 12 }) {
  return (
    <Child width={width} offset={offset}>
      {children}
    </Child>
  )
}
